import React, { Suspense } from "react";
import Login from "./Login/Login";
import { Switch, Route } from "react-router-dom";
import ChangePassword from "./Login/ChangePassword";
import Div100vh from "react-div-100vh";
import OtherLinks from "./Login/OtherLinks";
const Home = React.lazy(() => import("./Home"));

export default function Application() {
  return (
    <Div100vh style={{ backgroundColor: "#ebebeb", height: "99.99rvh" }}>
      <Switch>
        <Route path="/login">
          <div style={{ minHeight: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ margin: "auto", overflow: "auto", maxHeight: "85vh" }}>
              <Login />
            </div>
            <OtherLinks />
          </div>
        </Route>
        <Route path="/changePassword/:token+">
          <div style={{ height: "100%", display: "flex" }}>
            <ChangePassword />
          </div>
        </Route>

        <Route path="/">
          <Suspense fallback={<div>loading</div>}>
            <Home />
          </Suspense>
        </Route>
      </Switch>
    </Div100vh>
  );
}
