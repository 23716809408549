import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useHttpGet, useHttpPost } from "../utility/RestAPI";
import LoadingIndicatorSWAC from "./LoadingIndicatorSWAC/LoadingIndicatorSWAC";
import MovablePopup from "./MovablePopup/MovablePopup";
import { config } from "../config";

export default function FloatingNote({ onClose, title, docAdminId, anchor, editAccess, isCatalogue, refetcher }) {
  const [text, setText] = useState("");
  const [initText, setInitText] = useState("");
  const [initPos, setInitPos] = useState([0, 0]);
  const unmounted = useRef(false);
  const { loading } = useHttpGet(isCatalogue ? "api/catalogueNote" : "api/note", {
    param: { docAdminId },
    onComplete: (data) => {
      setInitText(data.text);
      setText(data.text);
      if (refetcher) {
        refetcher();
      }
    },
  });
  const httpSaveNote = useHttpPost(isCatalogue ? "api/catalogueNote" : "api/note", {
    onComplete: () => {
      if (!unmounted.current) setInitText(text);
    },
  });
  useLayoutEffect(() => {
    setInitPos([
      Math.min((Math.random() - 0.5) * 30 + anchor.getBoundingClientRect().left, window.innerWidth - 200),
      Math.random() * 20 + anchor.getBoundingClientRect().top + anchor.getBoundingClientRect().height,
    ]);
  }, [anchor]);
  useEffect(() => {
    let to = null;
    if (text !== initText) {
      to = setTimeout(() => {
        httpSaveNote({ docAdminId, note: text });
      }, config.saveNoteWaitType);
    }
    return () => {
      clearTimeout(to);
    };
  }, [text, initText, docAdminId, httpSaveNote]);
  return (
    <>
      <MovablePopup
        vis={true}
        title={title}
        posX={initPos[0] + "px"}
        posY={initPos[1] + "px"}
        percX={0}
        width={"200px"}
        clicked={() => {
          unmounted.current = true;
          if (text !== initText) {
            httpSaveNote({ docAdminId, note: text });
          }
          onClose();
        }}>
        {loading && <LoadingIndicatorSWAC />}
        {!loading && (
          <textarea
            className="swacTextArea"
            disabled={!editAccess}
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows="10"
          />
        )}
      </MovablePopup>
    </>
  );
}
