import React from "react";
import "./App.css";
import "@abb/abb-common-ux-react/styles.css";
import { ContextProvider, UploadingProvider } from "./GlobalContext";
import Application from "./components/Application";
import { BrowserRouter as Router } from "react-router-dom";
import SnackbarHandler from "./components/SnackbarHandler/SnackbarHandler";
import { ConfigurationProvider } from "./components/ConfigurationContext";
import NotesHandler from "./components/NotesHandler";
import "./components/Tree/Tree.css";
import "./components/Tree/Expander.css";
import "./components/Tree/Document.css";

function App() {
  return (
    <Router>
      <ContextProvider>
        <UploadingProvider>
          <ConfigurationProvider>
            <SnackbarHandler />
            <NotesHandler />
            <Application />
          </ConfigurationProvider>
        </UploadingProvider>
      </ContextProvider>
    </Router>
  );
}

export default App;
