import { useEffect, useState, useRef } from "react";

const useBreakpoint = (queries) => {
  const initialized = useRef(false);
  const dep = JSON.stringify(queries);
  if (!initialized.current) {
    initialized.current = {};
    for (const key of Object.keys(queries)) {
      initialized.current[key] = window.matchMedia(queries[key]).matches;
    }
  }
  const [queryMatch, setQueryMatch] = useState(initialized.current);
  useEffect(() => {
    const mediaQueryLists = {};
    const keys = Object.keys(queries);
    // To check whether event listener is attached or not
    let isAttached = false;
    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
        return acc;
      }, {});
      //Setting state to the updated matches
      // when document either starts or stops matching a query
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      //Setting state to initial matching queries
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }
    window.addEventListener("resize", handleQueryListener);

    return () => {
      //If event listener is attached then remove it when deps change
      window.removeEventListener("resize", handleQueryListener);
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dep]);

  return queryMatch;
};

export default useBreakpoint;
