import React from "react";
import "./LoadingIndicatorSWAC.css";

export default function LoadingIndicatorSWAC({
  amount = 15,
  animationDuration = 2.5,
  size = "100px",
  color = "lightGray",
  thick = "8px",
  style,
  text = "",
  progress = null,
}) {
  const inter = 360 / amount;
  let loaded = progress?.lengthComputable ? Math.round((progress.loaded / progress.total) * 100) : 0;
  if (progress && progress.loaded === progress.total && progress.lengthComputable) {
    loaded = 0;
  }
  const angle = (loaded / 100) * Math.PI * 2;

  return (
    <div
      className="loadingindicator-container"
      style={{
        "--loadingindicator-steps": inter + "deg",
        "--loadingindicator-size": size,
        ...style,
      }}>
      <div style={{ margin: "auto" }}>
        <p>{text}</p>
        {progress?.lengthComputable && parseInt(size.slice(0, -2)) >= 45 && (
          <p style={{ textAlign: "center" }}>{loaded + "%"}</p>
        )}
      </div>
      {Array.from(Array(amount)).map((_, i) => {
        return (
          <div key={i} className="indicator-container" style={{ transform: `rotate(${inter * i}deg)` }}>
            <div
              style={{
                animationDelay: `${i / (amount / animationDuration)}s`,
                animationDuration: animationDuration + "s",
                borderLeft: `${color} solid ${thick}`,
                borderTop: `${color} solid ${thick}`,
              }}></div>
          </div>
        );
      })}
      <span className="loadingindicator-smootherContainer" style={{ animationDuration: animationDuration + "s" }}>
        <div
          className="loadingindicator-smoother"
          style={{
            borderLeft: `${color} solid ${thick}`,
            borderTop: `${color} solid ${thick}`,
          }}></div>
      </span>
      <svg viewBox="0 0 100 100" width={size} height={size} style={{ position: "absolute" }}>
        {loaded <= 50 && (
          <path
            d={`M50 4 A46 46 0 0 1 ${46 * Math.sin(angle) + 50} ${-46 * Math.cos(angle) + 50}`}
            stroke="blue"
            fill="transparent"
            strokeWidth="8"
          />
        )}
        {loaded > 50 && (
          <path
            d={`  M50 4 A46 46 0 0 1 ${50} ${96} M50 96 A46 46 0 0 1 ${-46 * Math.sin(angle - Math.PI) + 50} ${
              46 * Math.cos(angle - Math.PI) + 50
            }`}
            stroke="blue"
            fill="transparent"
            strokeWidth="8"
          />
        )}
      </svg>
    </div>
  );
}
