import { config } from "../config";
import { ObjectID } from "bson";
import { simpleClone } from "./simpleDeepClone";

export function stripTree(tree) {
  if (tree.type) {
    delete tree["type"];
  }
  tree.children.forEach((n, i) => {
    if (n.children) {
      tree.children[i] = { _id: n._id, name: n.name, children: n.children };
      if (n.isDummy) {
        tree.children[i].isDummy = true;
      }
      stripTree(n);
    } else {
      tree.children[i] = { _id: n._id.slice(0, 24), revision: n.revision };
    }
  });
}

export function selectAllChildren(tree, isSelect, selecteds, disabled = new Set()) {
  tree.children.forEach((node) => {
    if (!node.children) {
      if (!disabled.has(node._id)) {
        selecteds[node._id] = isSelect;
      }
    } else {
      if (!disabled.has(node._id)) {
        selecteds[node._id] = isSelect ? 2 : 0;
        selectAllChildren(node, isSelect, selecteds, disabled);
      }
    }
  });
}

export function numberOfNodes(tree) {
  let cnt = 0;
  for (const c of tree.children) {
    cnt++;
    if (c.children) {
      cnt += numberOfNodes(c);
    }
  }
  return cnt;
}

export function determineCheckStateOfExpander(expander, selecteds, special = true) {
  let allSelected = true;
  let allDeselected = true;
  for (let c of expander.children) {
    if (selecteds[c._id] || selecteds[c._id] === 2 || selecteds[c._id] === 1) {
      allDeselected = false;
    }
    if (!selecteds[c._id] || selecteds[c._id] === 0 || selecteds[c._id] === 1) {
      allSelected = false;
    }
  }
  if (allSelected && allDeselected) return null;
  if (allSelected) return special && expander._id !== config.mostTopCatId ? 1 : 2;
  if (allDeselected) return 0;
  return 1;
}

export function determineAllSelecteds(parent, path, type, selecteds, special = true, disabled = new Set()) {
  const _id = parent._id;
  if (type) {
    //folder
    if (selecteds[_id] === 1) {
      selectAllChildren(parent, false, selecteds, disabled);
      selecteds[_id] = 0;
    } else if (selecteds[_id] === 2) {
      selecteds[_id] = parent.children.length === 0 || !special ? 0 : 1;
      if (!special) selectAllChildren(parent, false, selecteds, disabled);
    } else {
      selectAllChildren(parent, true, selecteds, disabled);
      selecteds[_id] = 2;
    }
  } else {
    selecteds[_id] = !selecteds[_id];
  }
  path.reverse().forEach((p) => {
    selecteds[p.parent._id] = determineCheckStateOfExpander(p.parent, selecteds, special);
  });
  return selecteds;
}

export function setCategorySelectedState(tree, selecteds, special = false) {
  let selectedInChildren = 0;
  let hasSubCats = false;
  const docs = getDocumentList(tree);
  if (docs.length === 0) {
    return selecteds[tree._id];
  }
  for (let n of tree.children) {
    if (!n.children) {
      if (selecteds[n._id]) {
        selectedInChildren++;
      }
    } else {
      let subSel = setCategorySelectedState(n, selecteds, special);
      hasSubCats = subSel > 0 || hasSubCats;
      if (subSel === 2) selectedInChildren++;
      else if (subSel === 1) {
        selecteds[tree._id] = 1;
      }
    }
  }
  if (selectedInChildren === 0 && !hasSubCats) {
    if (special) {
      selecteds[tree._id] = selecteds[tree._id] === 2 ? 2 : 0;
    } else {
      selecteds[tree._id] = 0;
    }
  } else if (tree.children.length === selectedInChildren && selecteds[tree._id] !== 1) {
    selecteds[tree._id] = special && selecteds[tree._id] !== 2 ? 1 : 2;
  } else {
    if (special && selecteds[tree._id] === 2) {
    } else {
      selecteds[tree._id] = 1;
    }
  }
  return selecteds[tree._id];
}
function getCatalogueList(tree, dirList = []) {
  dirList.push(tree);
  tree.children.filter((e) => e.children).forEach((e) => getCatalogueList(e, dirList));
  return dirList;
}

export function findCatIdChangeAtMerge(tree1, tree2, changedCats = {}, noChangeCats = {}) {
  const folders = tree1.children.map((f) => f.name);
  tree2.children
    .filter((e) => e.children)
    .forEach((node) => {
      let index = folders.indexOf(node.name);
      if (index === -1) {
        const toC = getCatalogueList(node);
        toC.forEach((e) => {
          changedCats[e._id] = String(new ObjectID());
        });
      } else {
        noChangeCats[node._id] = tree1.children[index]._id;
        findCatIdChangeAtMerge(tree1.children[index], node, changedCats, noChangeCats);
      }
    });
  return { noChangeCats, changedCats };
}

export function getAllIds(tree, ids, docs, catalogues) {
  if (catalogues) ids.push(tree._id);
  tree.children.forEach((t) => {
    if (t.children) {
      getAllIds(t, ids, docs, catalogues);
    } else {
      if (docs) ids.push(t._id);
    }
  });
  return ids;
}

export function errorIfNoFile(tree, dispatch, share = false) {
  const docs = Array.isArray(tree) ? tree : getDocumentList(tree);
  const noFileDocument = docs.find((e) => !e.doc.info.fileName);
  if (noFileDocument) {
    dispatch({
      type: "addSnackbar",
      snackbarType: "error",
      text:
        (!share ? "Tried to download document that has no file: " : "Tried to share document that has no file: ") +
        noFileDocument.doc.name,
      id: new ObjectID(),
    });
    return true;
  }
  return false;
}

export function getDummyNotDummyTypesFromSelected(tree, selecteds, status = { foundDummy: false, foundReal: false }) {
  for (const e of tree.children.filter((e) => selecteds[e._id])) {
    if (!e.children) {
      if (e.isDummy) {
        status.foundDummy = true;
      } else {
        status.foundReal = true;
      }
    } else {
      getDummyNotDummyTypesFromSelected(e, selecteds, status);
    }
    if (status.foundReal && status.foundDummy) {
      return status;
    }
  }
  return status;
}

export function buildTreeFromSelecteds(origTree, selecteds, noDocs = [], conflicts = []) {
  const newTree = { children: [], name: origTree.name, _id: origTree._id };
  origTree.children.forEach((e) => {
    if (selecteds[e._id] === true) {
      const doc = noDocs.find((d) => d.doc._id.slice(0, 24) === e._id.slice(0, 24));
      if (doc) {
        conflicts.push(e.name);
      }
      newTree.children.push(e);
    } else if (selecteds[e._id] === 2) {
      newTree.children.push(buildTreeFromSelecteds(e, selecteds, noDocs, conflicts));
    } else if (selecteds[e._id] === 1) {
      newTree.children.push(buildTreeFromSelecteds(e, selecteds, noDocs, conflicts));
    }
  });
  return newTree;
}

export function buildTreeFromSelectedsNoRoot(origTree, selecteds, noDocs = new Set(), conflicts = []) {
  const newTree = { children: [], name: origTree.name, _id: origTree._id };
  if (origTree.isDummy) {
    newTree.isDummy = true;
  }
  origTree.children.forEach((e) => {
    if (selecteds[e._id] === true) {
      if (noDocs.has(e.name)) {
        conflicts.push({ type: "doc", name: e.name });
      }
      if (!newTree.children.find((c) => c.name === e.name)) {
        newTree.children.push(e);
      }
    } else if (selecteds[e._id] === 2) {
      const subTree = buildTreeFromSelectedsNoRoot(e, selecteds, noDocs, conflicts);
      newTree.children.push(subTree);
    } else if (selecteds[e._id] === 1) {
      const subTree = buildTreeFromSelectedsNoRoot(e, selecteds, noDocs, conflicts);
      subTree.children.forEach((c) => {
        if (!newTree.children.find((e) => e.name === c.name)) {
          newTree.children.push(c);
        }
      });
    }
  });
  return newTree;
}

export function getPathsMapFromTreeNodes(tree, currPath = "/", theMap = {}) {
  theMap[tree._id] = currPath;
  currPath += tree.name + "/";
  tree.children.forEach((e) => {
    if (e.children) {
      getPathsMapFromTreeNodes(e, currPath, theMap);
    } else {
      theMap[e._id] = currPath;
    }
  });
  return theMap;
}

export function branchNotEmpty(branch) {
  if (branch.children.length === 0) {
    return false;
  }
  for (const c of branch.children) {
    if (c.children) {
      if (branchNotEmpty(c)) {
        return true;
      }
    } else {
      return true;
    }
  }
}

export function getPxPosFromPath(tree, path, accPx = 0, pathIter = 0) {
  if (path[pathIter] === '"Outside category"') {
    pathIter++;
  }
  for (const c of tree.children
    .filter((e) => !e.children)
    .sort((f, s) => (f.name.toLowerCase() > s.name.toLowerCase() ? 1 : -1))) {
    accPx += 32;
    if (c.name === path[pathIter]) {
      if (pathIter + 1 === path.length) {
        return accPx;
      }
    }
  }
  for (const c of tree.children
    .filter((e) => e.children)
    .sort((f, s) => (f.name.toLowerCase() > s.name.toLowerCase() ? 1 : -1))) {
    accPx += 32;
    if (c.name === path[pathIter]) {
      if (pathIter + 1 === path.length) {
        return accPx;
      }
      accPx += getPxPosFromPath(c, path, 0, pathIter + 1);
      return accPx;
    }
  }
}

export function findIdsFromNamePath(tree, path, ids = [], iter = 0) {
  if (path[iter] === '"Outside category"') {
    iter++;
  }
  for (const c of tree.children) {
    if (c.name === path[iter]) {
      ids.push(c._id);
      if (path.length === iter + 1) {
        return ids;
      }
      return findIdsFromNamePath(c, path, ids, iter + 1);
    }
  }
}

export function grabSelectedsFromTree(tree, selecteds, res = []) {
  tree.children.forEach((e) => {
    if (selecteds[e._id] === true) {
      res.push(e);
    } else if (selecteds[e._id] === 2) {
      res.push(e);
    } else if (selecteds[e._id] === 1) {
      res.push(...grabSelectedsFromTree(e, selecteds));
    }
  });
  return res;
}

export function getDocumentList(tree, docList = [], existing = new Set(), noDifferentFps = true, allDocs = false) {
  tree.children.forEach((e) => {
    if (e.children) {
      getDocumentList(e, docList, existing, noDifferentFps, allDocs);
    } else {
      if (!allDocs) {
        if (noDifferentFps) {
          if (existing.has(e._id.slice(0, 24))) return;
          existing.add(e._id.slice(0, 24));
        } else {
          if (existing.has(e._id.slice(0, 24) + e.revision)) return;
          existing.add(e._id.slice(0, 24) + e.revision);
        }
      }
      docList.push({ doc: e, parent: tree });
    }
  });
  return docList;
}

export function getDocumentListSorted(tree, noDifferentFps = true) {
  const list = getDocumentList(tree, [], new Set(), noDifferentFps);
  list.sort((first, second) => {
    return first.doc.name.toLowerCase() > second.doc.name.toLowerCase() ? 1 : -1;
  });
  return list;
}

export function findNodeWithProperty(tree, pred) {
  for (const n of tree.children) {
    if (pred(n)) {
      return true;
    }
    if (n.children) {
      const res = findNodeWithProperty(n, pred);
      if (res) {
        return true;
      }
    }
  }
  return false;
}

export function getCategoryList(tree, catList = []) {
  tree.children.forEach((e) => {
    if (e.children) {
      catList.push(e);
      getCategoryList(e, catList);
    }
  });
  return catList;
}

export function removeElements(tree, ids) {
  const idsToRemove = typeof ids.has === "undefined" ? new Set(ids) : ids;
  tree.children = tree.children.filter((e) => !idsToRemove.has(e._id));
  tree.children.filter((e) => e.children).forEach((e) => removeElements(e, idsToRemove));
}

export function removeDocuments(tree, ids, inverse = true) {
  tree.children = tree.children.filter((e) => {
    if (e.children) return true;
    const r = inverse ? ids.has(e._id) : !ids.has(e._id);
    return r;
  });
  tree.children.forEach((n) => {
    if (n.children) {
      removeDocuments(n, ids, inverse);
    }
  });
}

export function hasDummyDoc(tree) {
  if (!tree.children) {
    return tree.isDummy;
  }
  for (const n of tree.children) {
    if (n.children) {
      if (hasDummyDoc(n)) {
        return true;
      }
    } else {
      if (n.isDummy) return true;
    }
  }
  return false;
}
export function hasOnlyDummyDoc(tree) {
  for (const n of tree.children) {
    if (n.children) {
      if (!hasOnlyDummyDoc(n)) {
        return false;
      }
    } else {
      if (!n.isDummy) return false;
    }
  }
  return true;
}

export function findAllNodesByName(tree, name, nodes = [], path = []) {
  path.push(tree.name);
  if (tree.name === name) {
    nodes.push({ node: tree, path: path.slice(1, path.length) });
  }
  for (const node of tree.children.filter((e) => e.children)) {
    findAllNodesByName(node, name, nodes, [...path]);
  }
  return nodes;
}

export function mergeTreesLocal(tree1, tree2, addedDocs = [], changeIds = {}) {
  let res = { name: tree1.name, _id: tree1._id, children: [...tree1.children] };
  const docs = res.children.filter((e) => !e.children).map((d) => d._id);
  const folders = res.children.map((f) => f.name);
  tree2.children.forEach((node) => {
    if (!node.children) {
      if (!docs.find((d) => String(d) === String(node._id))) {
        res.children.push(node);
        addedDocs.push({
          docId: String(node._id),
          parentId: tree1._id.toString(),
          revision: node.revision,
        });
      }
    } else {
      let index = folders.indexOf(node.name);
      if (index === -1) {
        res.children.push(node);
        getCatalogueList(node).forEach((e) => {
          e._id = changeIds[String(e._id)] || String(new ObjectID());
        });
        getDocumentList(node).forEach(({ doc, parent }) => {
          addedDocs.push({
            docId: doc._id.toString(),
            parentId: parent._id,
            revision: doc.revision,
          });
        });
      } else {
        res.children[index] = mergeTreesLocal(res.children[index], node, addedDocs, changeIds);
      }
    }
  });
  return res;
}

export function findNodeById(tree, _id, path = []) {
  path.push(tree);
  if (tree._id === _id) {
    return { node: tree, parent: null, path };
  }
  const found = tree.children.find((e) => e._id === _id);
  if (found) {
    path.push(found);
    return { node: found, parent: tree, path };
  }
  for (const node of tree.children.filter((e) => e.children)) {
    const n = findNodeById(node, _id, [...path]);
    if (n) return n;
  }
  return null;
}

export function switchPos(tree, draggingId, toDirId) {
  const { node: fromNode, parent: fromParent } = findNodeById(tree, draggingId);
  const toParent = findNodeById(tree, toDirId).node;
  fromParent.children = fromParent.children.filter((e) => e._id !== draggingId);
  toParent.children.push(fromNode);
}

export function expandAllTree(tree, expandeds) {
  const has = expandeds.includes(tree._id);
  if (!has) {
    expandeds.push(tree._id);
  }
  tree.children.forEach((e) => {
    if (e.children) {
      expandAllTree(e, expandeds);
    }
  });
}

export function findEmptyCats(tree, emptyCats = new Set()) {
  tree.children.forEach((n) => {
    if (n.children) {
      if (getDocumentList(n).length === 0) {
        emptyCats.add(n._id);
      } else {
        findEmptyCats(n, emptyCats);
      }
    }
  });
  return emptyCats;
}

export function findTypesToRemove(tree, orFilter = true, types = [], toRemove = new Set(), filterLabel = null) {
  tree.children.forEach((n) => {
    let filterLbl = filterLabel;
    if (!filterLabel) {
      filterLbl = "type";
    }

    const nodeType = simpleClone(n[filterLbl] || n.info[filterLbl]);
    if ((n.children && n?.documentChange?.change) || (!n.children && n.change)) {
      nodeType.push("__New document__");
    }
    if (orFilter) {
      if (
        !types.some((filtType) => {
          return nodeType.some((t) => t === filtType) ? true : false;
        })
      ) {
        toRemove.add(n._id);
      }
    } else {
      if (
        !types.every((filtType) => {
          return nodeType.some((t) => t === filtType) ? true : false;
        })
      ) {
        toRemove.add(n._id);
      }
    }
    if (n.children && !toRemove.has(n._id)) {
      findTypesToRemove(n, orFilter, types, toRemove);
    }
  });
  return toRemove;
}

function searchTreeNameAndFilename(
  tree,
  searchStr,
  searchFilename = false,
  searchDescription = false,
  matchNodes = [],
  path = []
) {
  path.push(tree);
  const ss = new RegExp(searchStr, "i");
  tree.children.forEach((c) => {
    if (!c.children) {
      const searchTitle = c.name.search(ss);
      if (searchTitle > -1) {
        matchNodes.push({
          node: c,
          path,
          titleMatch: true,
          searchPosMatch: searchTitle,
        });
        return;
      }
      if (searchFilename && c.info.fileName) {
        const searchFilename = c.info.fileName.search(ss);
        if (searchFilename > -1) {
          matchNodes.push({
            node: c,
            path,
            titleMatch: false,
            searchPosMatch: searchFilename,
          });
          return;
        }
      }
      if (searchDescription && c.info.description) {
        const searchDescription = c.info.description.search(ss);
        if (searchDescription > -1) {
          matchNodes.push({
            node: c,
            path,
            titleMatch: false,
            searchPosMatch: searchDescription,
          });
          return;
        }
      }
    } else {
      searchTreeNameAndFilename(c, searchStr, searchFilename, searchDescription, matchNodes, [...path]);
    }
  });

  return matchNodes;
}

function validateRegex(str) {
  try {
    new RegExp(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function searchTitleInTree(tree, searchStr, searchDescription = true) {
  searchStr = searchStr.replace(/\*/g, ".*");
  if (!validateRegex(searchStr)) return [];
  return searchTreeNameAndFilename(tree, searchStr, true, searchDescription);
}

export function searchDeliveryInTree(tree, searchStr) {
  searchStr = searchStr.replace(/\*/g, ".*");
  if (!validateRegex(searchStr)) return [];
  return searchTreeNameAndFilename(tree, searchStr);
}

export function calculateDocsBelowForCatalogues(tree, unique = false, visited = new Set(), docsBelow = {}) {
  docsBelow[tree._id] = 0;
  tree.children.forEach((n) => {
    if (n.children) {
      docsBelow[tree._id] += calculateDocsBelowForCatalogues(n, unique, visited, docsBelow)[1];
    } else {
      if (unique && visited.has(n.name + n.revision)) return;
      if (unique) visited.add(n.name + n.revision);
      docsBelow[tree._id]++;
    }
  });
  return [docsBelow, docsBelow[tree._id]];
}

export function stripNotEditAccess(tree) {
  tree.children = tree.children.filter((c) => c.editAccess && !c.isLock && !c.isLocked);
  tree.children.forEach((c) => {
    if (c.children) {
      stripNotEditAccess(c);
    }
  });
  tree.children = tree.children.filter((c) => !c.children || c.children.length > 0);
}

export function stripTreeFromDocuments(tree) {
  tree.children = tree.children.filter((e) => e.children);
  tree.children.forEach((e) => {
    stripTreeFromDocuments(e);
  });
}

export function getTopSelecteds(tree, selecteds, topSel = []) {
  if (selecteds[tree._id] === 2) {
    topSel.push(tree._id);
    return topSel;
  }
  tree.children.forEach((c) => {
    const selVal = selecteds[c._id];
    if (selVal === true) {
      topSel.push(c._id);
      return;
    }
    if (selVal === 2) {
      topSel.push(c._id);
      return;
    }
    if (selVal === 1) {
      getTopSelecteds(c, selecteds, topSel);
    }
  });
  return topSel;
}

export function updateSelectedsToHaveSemiSelecteds(tree, selecteds, fullSelecteds = {}) {
  let foundSelecteds = false;
  if (selecteds.has(tree._id)) {
    fullSelecteds[tree._id] = 2;
    selectAllChildren(tree, true, fullSelecteds);
    return true;
  }
  tree.children.forEach((c) => {
    if (c.children) {
      if (selecteds.has(c._id)) {
        fullSelecteds[c._id] = 2;
        selectAllChildren(c, true, fullSelecteds);
        foundSelecteds = true;
      } else {
        foundSelecteds = updateSelectedsToHaveSemiSelecteds(c, selecteds, fullSelecteds);
        fullSelecteds[c._id] = 1;
      }
      if (!fullSelecteds[c._id]) {
        fullSelecteds[c._id] = 0;
      }
    } else {
      if (selecteds.has(c._id)) {
        fullSelecteds[c._id] = true;
        foundSelecteds = true;
      } else {
        fullSelecteds[c._id] = false;
      }
    }
  });
  if (foundSelecteds) {
    fullSelecteds[tree._id] = 1;
  }
  return foundSelecteds;
}
