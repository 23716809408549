import React, { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";
import "./SnackbarHandler.css";
import { Icon } from "@abb/abb-common-ux-react";
import { config } from "../../config";

const typeMap = {
  success: {
    color: config.notificationColors.success,
    icon: "abb/check-mark-circle-1",
  },
  error: { color: config.notificationColors.error, icon: "abb/error-circle-1" },
  information: {
    color: config.notificationColors.information,
    icon: "abb/information-circle-1",
  },
  warning: {
    color: config.notificationColors.warning,
    icon: "abb/warning-circle-1",
  },
};
export default function SnackbarHandler() {
  const { state, dispatch } = useContext(GlobalContext);

  return (
    <>
      {state.snackbars.map((sb, i) => {
        return (
          <div
            onAnimationEnd={() => {
              if (sb.initializedAnimation) {
                dispatch({ type: "removeSnackbar", id: sb.id });
              } else {
                sb.initializedAnimation = true;
              }
            }}
            key={sb.id}
            className="snackbar-content"
            style={{
              "--snackbar-top-pos": 50 * i + 20 + "px",
              backgroundColor: typeMap[sb.type].color,
              "--persistTimeSnackbar": "5s",
            }}>
            <div>
              <Icon name={typeMap[sb.type].icon} sizeClass="large" color={"white"} />
            </div>
            <p>{sb.text}</p>
            <div
              onClick={() => {
                dispatch({ type: "removeSnackbar", id: sb.id });
              }}>
              <Icon name="abb/close" sizeClass="medium" style={{ margin: "auto" }} />
            </div>
          </div>
        );
      })}
    </>
  );
}
