import React, { createContext, useReducer } from "react";

const initialConfig = {
  configuration: {
    allowNewTypes: true,
    allowNewLanguages: true,
    defaultTypes: ["pneumatics"],
    defaultLanguages: ["English"],
    downloadDocFormat: "docname_rev",
    showEmptyCatalogues: false,
    showDocsInCatalogues: true,
    orFilter: true,
    downloadNoViewable: true,
    shareLinkValidTime: 168,
    disabledContent: false,
  },
};
function configurationReducer(state, action) {
  switch (action.type) {
    case "setConfiguration":
      state.configuration = action.configuration;
      return { ...state };
    default:
      break;
  }
}

const ConfigurationContext = createContext();
const ConfigurationDispatchContext = createContext();

function ConfigurationProvider(props) {
  const [state, dispatch] = useReducer(configurationReducer, initialConfig);
  return (
    <ConfigurationContext.Provider value={state}>
      <ConfigurationDispatchContext.Provider value={dispatch}>{props.children}</ConfigurationDispatchContext.Provider>
    </ConfigurationContext.Provider>
  );
}

export { ConfigurationProvider, ConfigurationContext, ConfigurationDispatchContext };
