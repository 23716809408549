import React, { useContext } from "react";
import { GlobalContext } from "../GlobalContext";
import FloatingNote from "./FloatingNote";

export default function NotesHandler() {
  const { state, dispatch } = useContext(GlobalContext);

  return (
    <>
      {state.notesVis.map((e) => {
        return (
          <FloatingNote
            key={e._id}
            anchor={e.anchor}
            title={e.name}
            editAccess={e.editAccess}
            refetcher={e.refetch}
            onClose={() =>
              dispatch({
                type: "setNotes",
                notes: state.notesVis.filter((n) => n._id !== e._id),
              })
            }
            docAdminId={e._id}
          />
        );
      })}
      {state.catalogueNotesVis.map((e) => {
        return (
          <FloatingNote
            key={e._id}
            isCatalogue
            anchor={e.anchor}
            refetcher={e.refetch}
            title={e.name}
            editAccess={true}
            onClose={() =>
              dispatch({
                type: "setCatalogueNotes",
                notes: state.catalogueNotesVis.filter((n) => n._id !== e._id),
              })
            }
            docAdminId={e._id}
          />
        );
      })}
    </>
  );
}
