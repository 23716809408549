import React, { createContext, useReducer } from "react";
import { getAllIds, switchPos, findNodeById, removeElements } from "./utility/TreeFunctions";
import { config } from "./config";
import { simpleClone } from "./utility/simpleDeepClone";
import crypto from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import { setRestAPIconfig } from "./utility/RestAPI";
import algorithmWorker from "workerize-loader!./utility/Algorithms"; // eslint-disable-line import/no-webpack-loader-syntax
const worker = new algorithmWorker();

const initialState = {
  headerName: "",
  currNavigation: config.navigations[0],
  video: { vis: false },
  catalogueDelSearch: "",
  catalogueExpandedsByName: [],
  findDoc: {
    filterExpanded: false,
    selecteds: {},
    currentFilter: { group: [], delivery: [], type: [], category: [] },
    contentSearchResult: null,
    searchType: 0,
    searchValue: "",
  },
  loggedInUser: {
    name: "",
    email: "",
    isLoggedIn: false,
    type: "Viewer",
    qrUser: false,
    sharedUser: false,
    licenseStatus: null,
  },
  nonDownloadableModal: null,

  statisticsView: {
    navigation: "Document",
    timePeriod: {
      start: Date.now() / 1000 - 3600 * 24 * 30,
      end: Date.now() / 1000,
    },
    docStat: {
      selectedDeliveries: {},
      selectedDoc: { _id: -1 },
    },
    delStat: {
      selectedDeliveries: {},
    },
  },

  settingsView: {
    currNavigation: config.settingsNavigation[0],
  },
  userView: {
    currNavigation: config.userNavigation[0],
  },
  copy: {
    tree: null,
    selecteds: null,
  },

  catalogue: {
    docAdminId: null,
    docAdminRev: 0,
    groupName: null,
    lockDate: 1595714242957,
    deliveryName: "",

    selecteds: {},
    expandeds: [],
  },
  curtainOut: true,
  qrcodeMode: false,
  snackbars: [],
  notesVis: [],
  catalogueNotesVis: [],
  contentCard: [
    {
      navigation: config.subNavigations[0],
      isMinimized: false,
      isFilterMinimized: false,
      [config.subNavigations[0]]: {
        level: [config.subNavigations[0]],
        docAdminId: null,
        docAdminRev: 0,
        docAdminLockDate: -1,
        tree: { children: [], types: [] },
        selecteds: {},
        expandeds: [],
      },
      [config.subNavigations[1]]: {
        level: [config.subNavigations[1]],
        docAdminId: null,
        docAdminRev: 0,
        docAdminLockDate: -1,
        groupId: null,
        tree: { children: [], types: [] },
        selecteds: {},
        expandeds: [],
      },
      [config.subNavigations[2]]: {
        level: [config.subNavigations[2]],
        tree: { children: [], types: [] },
        selecteds: {},
        expandeds: [], //då man pastar
      },
    },
    {
      navigation: config.subNavigations[1],
      isMinimized: false,
      isFilterMinimized: false,
      [config.subNavigations[0]]: {
        level: [config.subNavigations[0]],
        docAdminId: null,
        docAdminRev: 0,
        docAdminLockDate: -1,
        tree: { children: [], types: [] },
        selecteds: {},
        expandeds: [],
      },
      [config.subNavigations[1]]: {
        level: [config.subNavigations[1]],
        docAdminId: null,
        docAdminRev: 0,
        docAdminLockDate: -1,
        groupId: null,
        tree: { children: [], types: [] },
        selecteds: {},
        expandeds: [],
      },
      [config.subNavigations[2]]: {
        level: [config.subNavigations[2]],
        tree: { children: [], types: [] },
        selecteds: {},
        expandeds: [], //då man pastar
      },
    },
  ],
};

const initialUploadingContext = {
  uploading: {},
};

const iistate = simpleClone(initialState);
const GlobalContext = createContext(initialState);
const UploadingContext = createContext(initialUploadingContext);
const UploadingDispatchContext = createContext(initialUploadingContext);

function getCont(state, id) {
  return state.contentCard[id][state.contentCard[id].navigation];
}
function reducer(state, action) {
  let ids = null;
  let c = null;

  switch (action.type) {
    case "setFindDocState":
      return { ...state, findDoc: action.state };
    case "setNonDownloadableModal":
      return { ...state, nonDownloadableModal: action.nonDownloadableModal };
    case "setHeaderName":
      return { ...state, headerName: action.headerName };
    case "setAllDocsNames":
      state.allDocsNames = action.allDocsNames;
      state.allDocsNamesTag = base64.stringify(crypto(JSON.stringify(state.allDocsNames)));
      return { ...state };
    case "addDocToAllDocNames":
      state.allDocsNames.push(action.newDoc);
      state.allDocsNamesTag = base64.stringify(crypto(JSON.stringify(state.allDocsNames)));
      return { ...state };
    case "modDocInAllDocsNames":
      const index = state.allDocsNames.findIndex((e) => e._id === action.modDoc._id);
      state.allDocsNames[index] = action.modDoc;
      state.allDocsNamesTag = base64.stringify(crypto(JSON.stringify(state.allDocsNames)));
      return { ...state };

    case "setCatalogueDelSearch":
      return { ...state, catalogueDelSearch: action.search };

    case "setDocAdminFilterMinimized":
      state.contentCard[action.id].isFilterMinimized = action.isMinimized;
      return { ...state };

    case "restoreNavigation":
      const userState = simpleClone(initialState.loggedInUser);
      state = simpleClone(initialState);
      state.loggedInUser = userState;
      return { ...state };

    case "setOpenVideo":
      return { ...state, video: { ...action } };

    case "setNotes":
      state.notesVis = action.notes;
      return { ...state };

    case "setCatalogueNotes":
      state.catalogueNotesVis = action.notes;
      return { ...state };

    case "setQrCodeMode":
      state.qrcodeMode = true;
      return { ...state };

    case "setStatisticsNavigation":
      state.statisticsView.navigation = action.navigation;
      return { ...state };

    case "setUserNavigation":
      state.userView.currNavigation = action.navigation;
      return { ...state };

    case "setSettingsNavigation":
      state.settingsView.currNavigation = action.navigation;
      return { ...state };

    case "setContentCatdMinimize":
      state.contentCard[action.id].isMinimized = action.isMinimized;
      if (action.isMinimized && state.contentCard[action.id === 0 ? 1 : 0].isMinimized) {
        state.contentCard[action.id === 0 ? 1 : 0].isMinimized = false;
      }
      return { ...state };

    case "setUser":
      state.loggedInUser = action.user;
      state.loggedInUser.isLoggedIn = true;
      return { ...state };

    case "setLoggedIn":
      state = simpleClone(iistate);
      if (!action.user) {
        return { ...state };
      }
      state.loggedInUser = action.user;
      state.loggedInUser.isLoggedIn = action.loggedIn;
      state.loggedInUser.licenseStatus = action.licenseStatus;
      return { ...state };
    case "setCatalogueExpandedsByName":
      return { ...state, catalogueExpandedsByName: action.exp };
    case "setNavigation":
      return { ...state, currNavigation: action.navigation };
    case "setContentCardType":
      state.contentCard[action.id].navigation = action.newNavigation;
      const otherId = action.id === 0 ? 1 : 0;
      if (
        state.contentCard[action.id].navigation === state.contentCard[otherId].navigation &&
        ((getCont(state, action.id).level.length > 1 &&
          state.contentCard[action.id].navigation === config.subNavigations[0]) ||
          (getCont(state, action.id).level.length > 2 &&
            state.contentCard[action.id].navigation === config.subNavigations[1])) &&
        getCont(state, action.id).docAdminId === getCont(state, otherId).docAdminId &&
        getCont(state, action.id).docAdminRev === getCont(state, otherId).docAdminRev &&
        JSON.stringify(getCont(state, action.id).level) === JSON.stringify(getCont(state, otherId).level)
      ) {
        getCont(state, action.id).level.pop();
      }
      return { ...state };
    case "setContentCardLevel":
      c = getCont(state, action.id);
      c.level = action.level;
      c.docAdminId = action.docAdminId;
      c.docAdminRev = action.docAdminRev;
      c.expandeds = [];
      c.selecteds = {};
      if (action.groupId) c.groupId = action.groupId;
      if (action.revisionCount) c.revisionCount = action.revisionCount;
      c.name = action.name;
      return { ...state };

    case "setCatalogueDelivery":
      state.catalogue.docAdminId = action.docAdminId;
      state.catalogue.docAdminRev = action.docAdminRev;
      state.catalogue.lockDate = action.lockDate;
      state.catalogue.groupName = action.groupName;
      state.catalogue.deliveryName = action.name;
      state.catalogue.hasQr = action.hasQr;
      return { ...state };

    case "setHasQr":
      state.catalogue.hasQr = action.hasQr;
      return { ...state };

    case "setDocAdminRev":
      getCont(state, action.id).docAdminRev = action.docAdminRev;
      return { ...state };

    case "setDocAdminLockDate":
      getCont(state, action.id).docAdminLockDate = action.docAdminLockDate;
      return { ...state };

    case "setLevelName":
      getCont(state, action.id).level[action.level] = action.name;
      return { ...state };

    case "setDocAdminTreeFromDocAdmin":
      const cont = getCont(state, action.id);
      cont.docAdminLockDate = action.docAdminLockDate;
      if (action.levelName) {
        cont.level[action.level] = action.levelName;
      }
      cont.tree = action.tree;
      ids = getAllIds(action.tree, [], true, true);
      Object.keys(cont.selecteds)
        .filter((s) => !ids.includes(s))
        .forEach((d) => {
          delete cont.selecteds[d];
        });

      return { ...state };

    case "setDocAdminTree":
      getCont(state, action.id).tree = action.tree;

      ids = getAllIds(action.tree, [], true, true);
      Object.keys(getCont(state, action.id).selecteds)
        .filter((s) => !ids.includes(s))
        .forEach((d) => {
          delete getCont(state, action.id).selecteds[d];
        });

      return { ...state };

    case "switchPosInTree":
      switchPos(getCont(state, action.id).tree, action.fromId, action.toId);
      return { ...state };

    case "renameCategory":
      findNodeById(getCont(state, action.id).tree, action.nodeId).node.name = action.newName;
      return { ...state };

    case "removeElements":
      removeElements(getCont(state, action.id).tree, action.ids);
      return { ...state };

    case "setDocAdminTreeSelecteds":
      getCont(state, action.id).selecteds = action.selects;

      return { ...state };

    case "deselectAllSelecteds":
      c = getCont(state, action.id);
      Object.keys(c.selecteds).forEach((k) => {
        let e = c.selecteds[k];
        if (e === true) c.selecteds[k] = false;
        if (e === 1 || e === 2) c.selecteds[k] = 0;
      });

      return { ...state };

    case "setDocAdminExpandeds":
      getCont(state, action.id).expandeds = action.expandeds;

      return { ...state };

    case "setCurtain":
      state.curtainOut = action.isOut;
      return { ...state };

    case "addSnackbar":
      if (state.snackbars.includes(action.id)) return state;
      state.snackbars.push({
        text: action.text,
        type: action.snackbarType,
        id: action.id,
      });
      return { ...state };
    case "removeSnackbar":
      return {
        ...state,
        snackbars: state.snackbars.filter((e) => e.id !== action.id),
      };

    case "copy":
      state.copy.tree = simpleClone(action.tree);
      state.copy.selecteds = simpleClone(action.selecteds);
      return { ...state };

    case "cancelCopy":
      state.copy.tree = null;
      state.copy.selecteds = null;
      c = getCont(state, action.id);
      Object.keys(c.selecteds).forEach((k) => {
        let e = c.selecteds[k];
        if (e === true) c.selecteds[k] = false;
        if (e === 1 || e === 2) c.selecteds[k] = 0;
      });
      return { ...state };

    case "setDocRevision":
      findNodeById(getCont(state, action.id).tree, action.docId).node.revision = action.newRev;
      return { ...state };

    case "setStatisticsTimePeriod":
      state.statisticsView.timePeriod = action.timePeriod;
      return { ...state };

    case "setDocStatRelDels":
      if (action.selectedDeliveries) {
        state.statisticsView.docStat.selectedDeliveries = action.selectedDeliveries;
        if (action.cache) {
          state.statisticsView.docStat.selectedDeliveriesCache = action.selectedDeliveries;
        }
      } else {
        state.statisticsView.docStat.selectedDeliveries = state.statisticsView.docStat.selectedDeliveriesCache;
      }

      return { ...state };

    case "setDelStatRelDels":
      state.statisticsView.delStat.selectedDeliveries = action.selectedDeliveries;

      return { ...state };

    case "setDocStatDoc":
      state.statisticsView.docStat.selectedDoc = action.doc;
      return { ...state };

    default:
      console.error("ATTENTION! the type in action is misspelled ", action.type);
      break;
  }
}

function uploadingReducer(state, action) {
  switch (action.type) {
    case "setUploadingItem":
      if (action.itemName in state.uploading) {
        return { ...state };
      } else {
        state.uploading[action.itemName] = {
          progress: { loaded: 0, total: -1 },
        };
      }
      return { ...state };

    case "setUploadingItems":
      for (const itemName of action.itemNames) {
        if (!(itemName in state.uploading)) {
          state.uploading[itemName] = {
            progress: { loaded: 0, total: -1 },
          };
        }
      }
      return { ...state };

    case "updateUploadingItem":
      if (state.uploading[action.itemName]) {
        state.uploading[action.itemName].progress = action.progress;
      }
      return { ...state };

    case "setParsingItem":
      if (action.itemName in state.uploading) {
        return { ...state };
      } else {
        state.uploading[action.itemName] = {
          progress: { loaded: 0, total: -1, parsed: 0, totalToParse: -1 },
          parseCancelToken: action.cancelToken,
        };
      }
      return { ...state };

    case "removeUploadingItem":
      delete state.uploading[action.itemName];
      return { ...state };

    case "setCancelToken":
      state.uploading[action.itemName]["cancelToken"] = action.cancelToken;
      return { ...state };

    case "cancelUpload":
      state.uploading[action.itemName].cancelToken.cancel("user canceled the upload");
      delete state.uploading[action.itemName];
      return { ...state };

    case "cancelParse":
      state.uploading[action.itemName].parseCancelToken.cancel("user canceled the parse");
      delete state.uploading[action.itemName];
      return { ...state };

    default:
      break;
  }
}

function ContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  setRestAPIconfig({
    failedCallback: () => {
      console.log("failed");
      dispatch({ type: "setLoggedIn", loggedIn: false });
    },
  });
  return <GlobalContext.Provider value={{ state, dispatch }}>{props.children}</GlobalContext.Provider>;
}

function UploadingProvider(props) {
  const [uploadingState, uploadingDispatch] = useReducer(uploadingReducer, initialUploadingContext);
  return (
    <UploadingContext.Provider value={uploadingState}>
      <UploadingDispatchContext.Provider value={uploadingDispatch}>{props.children}</UploadingDispatchContext.Provider>
    </UploadingContext.Provider>
  );
}

export {
  GlobalContext,
  ContextProvider,
  initialState,
  getCont,
  UploadingProvider,
  UploadingContext,
  UploadingDispatchContext,
  worker,
};
