import React, { useState } from "react";
import { LoginScreen, Input } from "@abb/abb-common-ux-react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { useHttpGet, useHttpPost } from "../../utility/RestAPI";
import LoadingIndicatorSWAC from "../LoadingIndicatorSWAC/LoadingIndicatorSWAC";
import crypto from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import "./Login.css";
import { config } from "../../config";

export default function ChangePassword() {
  const p = useParams();
  const history = useHistory();

  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [showState, setShowState] = useState(0);
  const { loading, data } = useHttpGet("api/validateChangePassToken", {
    param: { token: p.token },
    onFailed: (status) => {
      if (status === 403) {
        alert("The activation link has expired");
      }
      history.push("/login");
    },
  });
  const httpSetNew = useHttpPost("api/updatePassword", {
    onComplete: () => {
      setShowState(1);
      setTimeout(() => {
        setShowState(2);
      }, 2000);
    },
    onFailed: (status) => {
      if (status === 403) {
        alert("The link has expired");
      }
      history.push("/login");
    },
  });
  function login() {
    if (pass1 !== pass2) {
      return;
    }
    let pw = base64.stringify(crypto(pass1));
    httpSetNew({ token: p.token, newPass: pw });
  }
  if (loading) return <LoadingIndicatorSWAC style={{ margin: "auto" }} />;
  if (data.ok)
    return (
      <>
        {showState === 0 && (
          <LoginScreen
            style={{ maxWidth: "95vw", margin: "auto", maxHeight: "800px" }}
            showDefaultFields={false}
            productName={<p className="login-productMainName">{config.product.name}</p>}
            productSubName={
              <div>
                {" "}
                <p>Create a password for the site </p>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <p>Username:</p>
                  <p style={{ marginLeft: "5px", color: "#3366ff" }}>{data.user}</p>
                </div>
              </div>
            }
            customContent={() => {
              return (
                <div>
                  <Input
                    value={pass1}
                    instantValidation
                    showValidationIconWhenInvalid
                    showValidationBarWhenInvalid
                    validator={(v) => {
                      return v.length < config.minPasswordLength && v.length !== 0
                        ? { valid: false, text: "Password must be at least 9 characters long" }
                        : { valid: true, text: "ok" };
                    }}
                    onValueChange={(val) => {
                      setPass1(val);
                    }}
                    label="Type new password"
                    dataType="password"
                  />
                  <Input
                    value={pass2}
                    onValueChange={(val) => {
                      setPass2(val);
                    }}
                    label="Type new password"
                    dataType="password"
                    instantValidation
                    showValidationIconWhenInvalid
                    showValidationBarWhenInvalid
                    onKeyUp={(key) => {
                      if (key.keyCode === 13) {
                        login();
                      }
                    }}
                    validator={(v) => {
                      return v === pass1
                        ? { valid: true, text: "ok" }
                        : { valid: false, text: "Passwords do not match." };
                    }}
                  />
                </div>
              );
            }}
            loginButtonText="Submit"
            onLogin={() => {
              if (pass1 === pass2 && pass1.length > config.minPasswordLength - 1) {
                login();
              }
            }}
            productFullName={`${config.product.name} ${config.product.version}`}
          />
        )}
        {showState === 1 && (
          <LoginScreen
            loading
            style={{ maxWidth: "95vw", margin: "auto", maxHeight: "800px" }}
            showDefaultFields={false}
            productName={<p className="login-productMainName">{config.product.name}</p>}
            productSubName="Password changed, redirecting to login page"
            showLoginButton={false}
            productFullName={config.product.name + " " + config.product.version}
          />
        )}
        {showState === 2 && <Redirect to="/login" />}
      </>
    );
  else {
    return <Redirect to="/" />;
  }
}
