import React from "react";
import abbLogo from "./ABB_Logo_Screen_RGB_33px_@2x.png";

export default function ErrorPage({ text1, text2 }) {
  return (
    <div style={{ padding: "50px" }}>
      <div>
        <img src={abbLogo} alt="ABB" />
      </div>
      <h1>{text1}</h1>
      <h2>{text2}</h2>
    </div>
  );
}
