import React, { useState, useContext } from "react";
import { LoginScreen, RadioGroup, RadioOption, Input } from "@abb/abb-common-ux-react";
import { useHttpGet, useHttpPost } from "../../utility/RestAPI";
import { GlobalContext } from "../../GlobalContext";
import crypto from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import { Redirect } from "react-router-dom";
import "./Login.css";
import { detect } from "detect-browser";
import ErrorPage from "../ErrorPage";
import { config } from "../../config";

export default function Login() {
  const { state, dispatch } = useContext(GlobalContext);
  const [loginState, setLoginState] = useState([200, "OK"]);
  const [loadingLoggin, setLoadingLoggin] = useState(false);
  const [unreachable, setUnreachable] = useState(false);
  const [acceptedAgreement, setAcceptedAgreement] = useState(false);
  const [forgotPasswordScreen, setForgotPasswordScreen] = useState(0);
  const [email, setEmaul] = useState("");
  const [password, setPassword] = useState("");
  const [authOk, setAuthOk] = useState(true);

  const { refetch: checkLogin } = useHttpGet("api/userInfo", {
    onComplete: (data) => {
      setLoadingLoggin(false);
      dispatch({
        type: "setLoggedIn",
        loggedIn: data.status === "ok",
        user: data.user || {},
        licenseStatus: data.licenseStatus,
      });
    },
    onFailed: () => {
      setUnreachable(true);
    },
  });
  const httpLogout = useHttpPost("api/logOut", {
    onComplete: () => {
      dispatch({ type: "setLoggedIn", loggedIn: false });
    },
  });
  const { refetch: httpAuthenticate } = useHttpGet("api/login", {
    skip: true,
    onComplete: (data) => {
      setLoadingLoggin(false);
      if (!data.ok && !data.licenseStatus.ok) {
        dispatch({
          type: "setLoggedIn",
          user: {},
          loggedIn: data.ok,
          licenseStatus: data.licenseStatus,
        });
      } else {
        checkLogin();
      }
    },
    onFailed: (status, data) => {
      setLoginState([status, data]);
      setLoadingLoggin(false);
    },
  });
  const httpAccept = useHttpPost("api/acceptLicense", {
    onComplete: (data) => {
      console.log(data);
      if (data.authOk) {
        setAuthOk(true);
        checkLogin();
      } else if (data.authOk === false) {
        setLoadingLoggin(false);
        setAuthOk(false);
      }
    },
  });
  if (unreachable) {
    return <ErrorPage text1="Server unreachable" text2="Try again later." />;
  }
  const browser = detect();
  if (browser.name === "ie") {
    return <ErrorPage text1="Internet explorer is not supported" text2="Try another browser e.g. Chrome or Edge" />;
  }
  let loginText = "";
  if (loginState[0] === 404) {
    loginText = "No such user or wrong password";
  } else if (loginState[0] === 401) {
    loginText = "Wrong password.";
  } else if (loginState[0] === 403) {
    if (loginState[1] === "deactivated") {
      loginText = "You have been deactivated due to inactivity. Contact support.";
    }
  }
  let toRender = null;
  if (state.loggedInUser.isLoggedIn) toRender = <Redirect to="/" />;
  else if (state.loggedInUser?.licenseStatus?.ok === false) {
    const customButtons = [];
    if (!acceptedAgreement) {
      customButtons.push({
        text: "Back to login",
        handler: () => {
          httpLogout();
        },
      });
    }

    toRender = (
      <LoginScreen
        style={{ maxWidth: "95vw", margin: "auto", maxHeight: "800px" }}
        productName={<p className="login-productMainName">{config.product.name}</p>}
        showLoginButton={acceptedAgreement}
        productSubName={
          state.loggedInUser.licenseStatus.reason === "newText"
            ? "The agreement has changed. In order to use the app you need to accept the new agreement"
            : "In order to use this documentation app, you need to accept the agreement"
        }
        showDefaultFields={false}
        onLogin={() => {
          setLoadingLoggin(true);
          httpAccept({ email, password: base64.stringify(crypto(password)) });
        }}
        customButtons={customButtons}
        loading={loadingLoggin}
        customContent={() => {
          return (
            <div>
              <p
                style={{
                  overflowY: "auto",
                  maxHeight: "300px",
                  borderTop: "solid lightgray 1px",
                  borderBottom: "solid lightgray 1px",
                  whiteSpace: "pre-wrap",
                }}>
                {state.loggedInUser.licenseStatus.licenseText}
              </p>
              <RadioGroup
                value={acceptedAgreement}
                onChange={(c) => setAcceptedAgreement(c)}
                orientation="horizontal"
                style={{ marginTop: "10px" }}>
                <RadioOption value={true} text="Confirm agreement" />
                <RadioOption value={false} text="Decline agreement" />
              </RadioGroup>
              {acceptedAgreement && state.loggedInUser.licenseStatus.qrCode && (
                <>
                  {!authOk && <p style={{ color: "red" }}>{"No such user or wrong password"}</p>}
                  <Input
                    style={{ maxWidth: "240px" }}
                    label="Username"
                    icon="abb/user"
                    value={email}
                    onValueChange={(e) => setEmaul(e)}
                  />
                  <Input
                    style={{ maxWidth: "240px" }}
                    label="Password"
                    dataType="password"
                    icon="abb/lock-closed"
                    value={password}
                    onKeyUp={(key) => {
                      if (key.keyCode === 13) {
                        setLoadingLoggin(true);
                        httpAccept({ email, password: base64.stringify(crypto(password)) });
                      }
                    }}
                    onValueChange={(e) => setPassword(e)}
                  />
                </>
              )}
            </div>
          );
        }}
        productFullName={config.product.name + " " + config.product.version}
      />
    );
  } else if (forgotPasswordScreen === 0) {
    toRender = (
      <>
        <LoginScreen
          loading={loadingLoggin}
          style={{ maxWidth: "95vw", margin: "auto", maxHeight: "800px" }}
          productName={<p className="login-productMainName">{config.product.name}</p>}
          onLogin={(username, password, rememberMe) => {
            httpAuthenticate({
              username,
              password: base64.stringify(crypto(password)),
              rememberMe,
            });
            setLoadingLoggin(true);
          }}
          rememberMeText="Keep me logged in"
          customButtons={[
            {
              text: "Forgot password?",
              handler: () => {
                setForgotPasswordScreen(1);
              },
            },
          ]}
          customContent={() => {
            return loginState === 200 ? null : <p style={{ color: "red" }}>{loginText}</p>;
          }}
          productFullName={config.product.name + " " + config.product.version}
        />
      </>
    );
  } else if (forgotPasswordScreen === 1) {
    toRender = (
      <LoginScreen
        style={{ maxWidth: "95vw", margin: "auto", maxHeight: "800px" }}
        productName={<p className="login-productMainName">{config.product.name}</p>}
        productSubName={
          <div>
            <p style={{ color: "black" }}>
              Unfortunatelly our mailserver is currently not working properly. Please contact any of the follwing
              people:
            </p>
            <p style={{ color: "#3366ff" }}>niklas.hjelm-karlsson@se.abb.com</p>
            <p style={{ color: "#3366ff" }}>august.ramle@se.abb.com</p>
          </div>
        }
        showDefaultFields={false}
        customContent={() => {}}
        loginButtonText="Submit"
        showLoginButton={false}
        customButtons={[
          {
            text: "cancel",
            handler: () => {
              setForgotPasswordScreen(0);
            },
          },
        ]}
      />
    );
  } else if (forgotPasswordScreen === 2) {
    toRender = (
      <LoginScreen
        style={{ maxWidth: "95vw", margin: "auto", maxHeight: "800px" }}
        productName={<p className="login-productMainName">{config.product.name}</p>}
        productFullName={config.product.name + " " + config.product.version}
        showDefaultFields={false}
        productSubName="Email with link sent"
        loginButtonText="Back to login"
        showLoginButton={true}
        onLogin={() => {
          setForgotPasswordScreen(0);
        }}
      />
    );
  }
  return <>{toRender}</>;
}
