import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Icon, Button } from "@abb/abb-common-ux-react";
import useBreakpoint from "../../utility/useBreakpoint";

const query = {
  mini: "(max-width: 1130px)",
  small: "(max-width: 900px)",
  xsmall: "(max-width: 360px)",
  xxsmall: "(max-width: 330px)",
};
export default function MovablePopup(props) {
  const [dragPosition, setDragPosition] = useState([0, 0]);
  const dragStart = useRef([0, 0]);
  const match = useBreakpoint(query);
  useEffect(() => {
    if (props.vis) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    return () => (document.body.style.overflowY = "auto");
  }, [props.vis]);
  return ReactDOM.createPortal(
    <div
      className="modal-content"
      style={{
        "--modalwidth": props.width || "50%",
        left: `calc(${match.small ? "2.5%" : match.mini ? "10%" : `${props.percX ?? 25}%`} + ${props.posX || "0px"} + ${
          dragPosition[0]
        }px)`,
        top: `calc(${props.posY || props.mobile || "25%"} + ${dragPosition[1]}px)`,
        zIndex: 10,
        ...props.style,
      }}>
      <header
        onDragEnd={(e) => {
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragEnter={(e) => {
          e.preventDefault();
        }}
        className="modal-header"
        draggable={true}
        onDrag={(e) => {
          if (e.clientX === 0 || e.clientY === 0) return;
          setDragPosition([e.clientX - dragStart.current[0], e.clientY - dragStart.current[1]]);
        }}
        onDragStart={(e) => {
          dragStart.current = [e.clientX - dragPosition[0], e.clientY - dragPosition[1]];
        }}>
        <div style={{ display: "flex" }}>
          {props.icon && <Icon sizeClass="large" color="#FF7300" name={props.icon} style={{ margin: "5px" }} />}
          <h4>{props.title}</h4>
        </div>
        <button className="modal-closeButton" onClick={() => (props.clicked ? props.clicked("close") : null)}>
          <Icon name="abb/close" sizeClass="medium" />
        </button>
      </header>
      <>{props.children}</>
      {props.footer && (
        <footer className="modal-footer">
          {props.footer.cancel && (
            <Button
              type="discreet-blue"
              text={props.footer.cancel}
              onClick={() => (props.clicked ? props.clicked("cancel") : null)}
              sizeClass="small"
            />
          )}
          {props.footer.other && (
            <Button
              type="discreet-blue"
              text={props.footer.other}
              onClick={() => (props.onOther ? props.onOther() : null)}
              sizeClass="small"
              disabled={props.disabledOther}
            />
          )}
          {props.footer.ok && (
            <Button
              type="primary-blue"
              text={props.footer.ok}
              onClick={() => (props.onOk ? props.onOk() : null)}
              sizeClass="small"
              disabled={props.disabledOk}
            />
          )}
        </footer>
      )}
    </div>,
    document.getElementById("portal-root")
  );
}
