import React from "react";
import "./Login.css";

export default function OtherLinks() {
  return (
    <article
      style={{
        margin: "auto",
        backgroundColor: "white",
        maxWidth: "95vw",
        width: "720px",
        padding: "20px 40px",
        marginTop: "10px",
      }}>
      <h3 style={{ margin: "auto", textAlign: "center" }}>Other SWAC sites</h3>
      <ul
        className="login-link"
        style={{ listStyle: "none", display: "flex", justifyContent: "space-between", margin: "0", padding: 0 }}>
        <li style={{ padding: "0" }}>
          <a href="https://swacdocabb.com/filecheck">RAPID File Checker</a>
        </li>
        <li>
          <a href="https://swacdocabb.com/training-introduction">Training Introduction</a>
        </li>
      </ul>
    </article>
  );
}
