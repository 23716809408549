import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ObjectID } from "bson";

const triggers = {};
const longPollId = ObjectID().toString();
const longPolls = {};
const local =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development" || process.env.REACT_APP_FORCELOCAL === "123";
function registerGetTrigger(api, callback) {
  if (api in triggers) {
    triggers[api].push(callback);
  } else {
    triggers[api] = [callback];
  }
}
function unRegisterGetTrigger(api, callback) {
  triggers[api] = triggers[api].filter((e) => e !== callback);
}
const restAPIConfig = {
  ip: local ? window.location.hostname : process.env.REACT_APP_SERVER_DOMAIN,
  port: local ? process.env.REACT_APP_PORT : process.env.REACT_APP_SERVER_PORT,
  protocol: local ? "http" : "https",
};

function setRestAPIconfig({ failedCallback }) {
  restAPIConfig["failedCallback"] = failedCallback;
}

async function subscribe(path, onData, cancelToken, onDisconnect) {
  while (true) {
    try {
      await new Promise((r) => setTimeout(r, 1));
      const res = await axios.get(
        restAPIConfig.protocol + "://" + restAPIConfig.ip + ":" + restAPIConfig.port + "/" + path,
        {
          withCredentials: true,
          cancelToken,
          params: { id: longPollId, longPolls },
        }
      );
      onData(res.data);
    } catch (exp) {
      if (exp.message === "unmounting") {
        break;
      }
      if (exp.message === "Network Error") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log("network error, reconnecting");
      } else {
        if (onDisconnect) {
          if (JSON.stringify(exp) !== "{}") {
            console.log("disconnecting " + JSON.stringify(exp));
            onDisconnect();
            break;
          }
        }
      }
    }
  }
}

function useSubscribeLongPoll(onDisconnect) {
  const token = axios.CancelToken.source();
  useEffect(() => {
    async function sub() {
      await subscribe(
        "longPoll",
        (datas) => {
          for (const { data, path, param } of datas) {
            if (param === "@") {
              for (const para of Object.keys(longPolls[path])) {
                longPolls[path][para].forEach((cb) => {
                  cb(data);
                });
              }
            } else if (Array.isArray(param)) {
              for (const para of Object.keys(longPolls[path]).filter((p) => param.includes(p))) {
                longPolls[path][para].forEach((cb) => {
                  cb(data);
                });
              }
            } else {
              longPolls[path][param].forEach((cb) => {
                cb(data);
              });
            }
          }
        },
        token.token,
        onDisconnect
      );
    }
    sub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return () => {
      token.cancel("unmounting");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function registerLongPoll(path, param = "_", callback) {
  if (!(path in longPolls)) {
    longPolls[path] = {};
  }
  if (!(param in longPolls[path])) {
    longPolls[path][param] = [];
  }
  longPolls[path][param].push(callback);

  axios
    .post(
      restAPIConfig.protocol + "://" + restAPIConfig["ip"] + ":" + restAPIConfig["port"] + "/registerLongPoll",
      { path, param, id: longPollId },
      {
        withCredentials: true,
      }
    )
    .then((res) => {})
    .catch((exp) => {});
}

function unregisterLongPoll(path, param = "_", callback) {
  let updateServer = false;
  if (path in longPolls && param in longPolls[path]) {
    longPolls[path][param] = longPolls[path][param].filter((e) => e !== callback);
    if (longPolls[path][param].length === 0) {
      updateServer = true;
      delete longPolls[path][param];
      if (Object.keys(longPolls[path]).length === 0) {
        delete longPolls[path];
      }
    }
  }
  if (updateServer) {
    axios
      .delete(
        restAPIConfig.protocol + "://" + restAPIConfig["ip"] + ":" + restAPIConfig["port"] + "/unregisterLongPoll",
        {
          data: { path, param, id: longPollId },
          withCredentials: true,
        }
      )
      .then((res) => {})
      .catch((exp) => {});
  }
}

function useHttpGet(api, params, id) {
  const mounted = useRef(true);
  const received = useRef(false);
  const pendingGet = useRef(false);
  const source = useRef(null);
  const [progress, setProgress] = useState(null);

  const [resp, setResp] = useState(null);
  const param = params || {};
  const stringparam = "noNewFetchWhenNewParam" in param ? null : JSON.stringify(param);

  function toCall(para, id, triggerData) {
    if (pendingGet.current && "cancelIfPending" in param) {
      source.current.cancel("new req");
    }
    source.current = axios.CancelToken.source();
    pendingGet.current = true;
    axios
      .get(restAPIConfig.protocol + "://" + restAPIConfig["ip"] + ":" + restAPIConfig["port"] + "/" + api, {
        params: para || param.param,
        withCredentials: true,
        onDownloadProgress: param.getProgress ? (prog) => setProgress(prog) : null,
        cancelToken: source.current.token,
      })
      .then((res) => {
        pendingGet.current = false;
        received.current = true;
        if (!mounted.current) return;
        if ("onReceivedModify" in param) {
          setResp(param.onReceivedModify(res.data));
        } else if (!("noOutputWhenComplete" in param)) {
          setResp(res.data);
        }
        if ("onComplete" in param) {
          try {
            param.onComplete(res.data, id, para && "ignoreTrack" in para);
          } catch (e) {
            console.error("Error in onComplete: " + api);
            console.log(e);
            throw e;
          }
        }
        if ("onTriggerComplete" in param && para && "ignoreTrack" in para) {
          param.onTriggerComplete(triggerData, res.data, id);
        }
        if ("triggerGet" in param) {
          setTimeout(
            () => {
              param.triggerGet.forEach((t) => {
                if (t in triggers)
                  triggers[t].forEach((g) => {
                    g();
                  });
              });
            },
            "triggerDelay" in param ? param.triggerDelay : 0
          );
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }
        received.current = true;
        pendingGet.current = false;
        if ("failedCallback" in restAPIConfig && !("onFailed" in param)) {
          restAPIConfig["failedCallback"]();
        }

        if ("onFailed" in param) {
          param.onFailed(err.response ? err.response.status : null, err.response ? err.response.data : err.response);
        }
      });
  }

  useEffect(() => {
    mounted.current = true;
    if (param.reload) {
      received.current = false;
      setResp(null);
      if (param.onReloaded) {
        param.onReloaded();
      }
    }
    if (!param.skip) {
      toCall(param.param, id);
    }
    if ("pollInterval" in param && !param.skip) {
      const inter = setInterval(() => {
        toCall(param.param, id);
      }, param.pollInterval);
      return () => {
        mounted.current = false;
        clearInterval(inter);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.skip, stringparam]);
  useEffect(() => {
    if ("longPollTrigger" in param) {
      const callback = (data) => {
        toCall({ ...param.param, ignoreTrack: longPollId }, id, data);
      };
      registerLongPoll("/" + param["longPollTrigger"].path, param["longPollTrigger"].param, callback);
      return () => {
        unregisterLongPoll("/" + param["longPollTrigger"].path, param["longPollTrigger"].param, callback);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringparam, param.skip]);

  useEffect(() => {
    mounted.current = true;
    registerGetTrigger(api, toCall);
    return () => {
      mounted.current = false;
      unRegisterGetTrigger(api, toCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, stringparam]);

  return { loading: !received.current && !param.skip, data: resp, refetch: toCall, progress };
}
//in express server: post: req.body, get: req.query, delete: req.body
function useHttpPost(api, param, onUploadProgress) {
  function toCall(parameters, id) {
    let source = axios.CancelToken.source();
    axios
      .post(
        restAPIConfig.protocol + "://" + restAPIConfig["ip"] + ":" + restAPIConfig["port"] + "/" + api,
        parameters,
        {
          onUploadProgress: onUploadProgress ? (progress) => onUploadProgress(progress, id) : null,
          withCredentials: true,
          cancelToken: source.token,
        }
      )
      .then((res) => {
        if (param !== undefined) {
          if ("onComplete" in param) {
            param.onComplete(res.data, id);
          }

          if ("triggerGet" in param) {
            setTimeout(
              () => {
                param.triggerGet.forEach((t) => {
                  if (t in triggers)
                    triggers[t].forEach((g) => {
                      g();
                    });
                });
              },
              "triggerDelay" in param ? param.triggerDelay : 0
            );
          }
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          if ("onCancel" in param) {
            param.onCancel(
              err.message,
              () => {
                setTimeout(
                  () => {
                    param.triggerGet.forEach((t) => {
                      if (t in triggers)
                        triggers[t].forEach((g) => {
                          g();
                        });
                    });
                  },
                  "triggerDelay" in param ? param.triggerDelay : 0
                );
              },
              id
            );
          }
        } else if (param) {
          if ("onFailed" in param) {
            if (err.response) {
              param.onFailed(err.response.status, err.response.data, id);
            } else {
              param.onFailed(err.name, err.message, id);
            }
          }
        }
      });
    if (param && "onBegin" in param) {
      param.onBegin(source, id);
    }
  }
  return toCall;
}

function useHttpDelete(api, param) {
  function toCall(parameters) {
    axios
      .delete(restAPIConfig.protocol + "://" + restAPIConfig["ip"] + ":" + restAPIConfig["port"] + "/" + api, {
        data: parameters,
        withCredentials: true,
      })
      .then((res) => {
        if (param !== undefined) {
          if ("onComplete" in param) {
            param.onComplete(res.data);
          }
          if ("triggerGet" in param) {
            param.triggerGet.forEach((t) => {
              if (t in triggers)
                triggers[t].forEach((g) => {
                  g();
                });
            });
          }
        }
      });
  }
  return toCall;
}

export { useHttpGet, useHttpPost, useHttpDelete, restAPIConfig, setRestAPIconfig, useSubscribeLongPoll, longPollId };
